import React from "react";
import Header from "../../Component/common/Header";

function Terms() {
  return (
    <div className="px-24 max-[1250px]:px-16 py-4 relative mb-20 max-md:px-10 h-[600px] w-full max-md:h-auto  max-md:pb-0 max-md:-mb-10 max-sm:px-4 ">
      <Header color={"white"} />
      <div className=" mx-auto p-6">
        <h1 className="text-3xl font-bold mb-6 text-center">
          Terms and Conditions
        </h1>

        <section className="mb-4">
          <h2 className="text-xl font-semibold mb-2">1. Acceptance of Terms</h2>
          <p className="text-gray-700">
            By accessing or using the Travelwaxx website or services, you agree
            to be bound by these Terms and Conditions. If you disagree with any
            part of these terms, please do not use our services.
          </p>
        </section>

        <section className="mb-4">
          <h2 className="text-xl font-semibold mb-2">2. Website Use</h2>
          <ul className="list-disc pl-6 text-gray-700">
            <li>
              You agree to use the Travelwaxx website and services for lawful
              purposes only.
            </li>
            <li>
              You are responsible for maintaining the confidentiality of your
              account and password.
            </li>
            <li>
              You agree to provide accurate and up-to-date information when
              making a booking.
            </li>
          </ul>
        </section>

        <section className="mb-4">
          <h2 className="text-xl font-semibold mb-2">3. Booking and Payment</h2>
          <ul className="list-disc pl-6 text-gray-700">
            <li>All bookings are subject to availability and confirmation.</li>
            <li>
              Payment must be made in full at the time of booking using a valid
              payment method.
            </li>
            <li>
              We reserve the right to modify or cancel bookings due to
              unforeseen circumstances.
            </li>
            <li>
              Price changes may occur after booking confirmation due to airline
              fare adjustments.
            </li>
            <li>
              You are responsible for ensuring that travel documents (passports,
              visas, etc.) are valid and compliant with all relevant
              regulations.
            </li>
          </ul>
        </section>

        <section className="mb-4">
          <h2 className="text-xl font-semibold mb-2">
            4. Refunds and Cancellations
          </h2>
          <ul className="list-disc pl-6 text-gray-700">
            <li>
              Cancellation and refund policies vary based on airline rules and
              fare types.
            </li>
            <li>
              Please refer to the specific terms of your booking for details.
            </li>
            <li>Fees may apply for changes or cancellations.</li>
          </ul>
        </section>

        <section className="mb-4">
          <h2 className="text-xl font-semibold mb-2">
            5. Intellectual Property
          </h2>
          <p className="text-gray-700">
            The content on the Travelwaxx website, including but not limited to
            text, images, and logos, is protected by copyright and other
            intellectual property laws.
          </p>
        </section>

        <section className="mb-4">
          <h2 className="text-xl font-semibold mb-2">
            6. Limitation of Liability
          </h2>
          <ul className="list-disc pl-6 text-gray-700">
            <li>
              Travelwaxx is not liable for any direct, indirect, incidental, or
              consequential damages arising from the use of our website or
              services.
            </li>
            <li>
              We are not responsible for delays, cancellations, or other
              disruptions caused by airlines or other third parties.
            </li>
          </ul>
        </section>

        <section className="mb-4">
          <h2 className="text-xl font-semibold mb-2">7. Indemnification</h2>
          <p className="text-gray-700">
            You agree to indemnify and hold harmless Travelwaxx from any claims,
            damages, or liabilities arising from your use of the website or
            services.
          </p>
        </section>

        <section className="mb-4">
          <h2 className="text-xl font-semibold mb-2">8. Privacy</h2>
          <p className="text-gray-700">
            Your privacy is important to us. Please refer to our Privacy Policy
            for details on how we collect, use, and protect your personal
            information.
          </p>
        </section>

        <section className="mb-4">
          <h2 className="text-xl font-semibold mb-2">9. Governing Law</h2>
          <p className="text-gray-700">
            These Terms and Conditions shall be governed by and construed in
            accordance with the laws of [Jurisdiction].
          </p>
        </section>

        <section className="mb-4">
          <h2 className="text-xl font-semibold mb-2">10. Changes to Terms</h2>
          <p className="text-gray-700">
            We reserve the right to modify these Terms and Conditions at any
            time. Changes will be effective immediately upon posting on our
            website.
          </p>
        </section>

        <section className="mb-4">
          <h2 className="text-xl font-semibold mb-2">11. Contact Us</h2>
          <p className="text-gray-700">
            For any questions or concerns, please contact us at{" "}
            <a
              href="mailto:support@Travelwaxx.com"
              className="text-blue-500 underline"
            >
              support@Travelwaxx.com
            </a>
            .
          </p>
        </section>

        <section className="mb-4">
          <h2 className="text-xl font-semibold mb-2">
            Additional Clauses (as applicable):
          </h2>
          <ul className="list-disc pl-6 text-gray-700">
            <li>Disclaimer of Warranties</li>
            <li>User-Generated Content</li>
            <li>Dispute Resolution</li>
            <li>Accessibility</li>
          </ul>
        </section>
      </div>
    </div>
  );
}

export default Terms;
