import React from "react";
import Header from "../../Component/common/Header";

function Policy() {
  return (
    <div className="px-24 max-[1250px]:px-16 py-4 relative mb-20 max-md:px-10 h-[600px] w-full max-md:h-auto  max-md:pb-0 max-md:-mb-10 max-sm:px-4">
      <Header color={"white"} />
      <div className=" mx-auto my-7">
        <h1 className="text-2xl font-bold mb-4">Privacy Policy</h1>
        <p className="mb-4">
          Travelwaxx respects your privacy and is committed to protecting your
          personal information. This Privacy Policy outlines how we collect,
          use, disclose, and safeguard your information when you use our website
          and services.
        </p>

        <h2 className="text-xl font-semibold mb-3">Information We Collect</h2>
        <h3 className="text-lg font-semibold mb-2">Personal Information</h3>
        <p className="mb-4">
          When you book a flight, create an account, or contact our customer
          support, we may collect personal information such as your name, email
          address, phone number, billing and shipping address, passport
          information, and payment details.
        </p>

        <h3 className="text-lg font-semibold mb-2">Device Information</h3>
        <p className="mb-4">
          We may collect information about the device you use to access our
          website, such as your IP address, browser type, operating system, and
          device identifiers.
        </p>

        <h3 className="text-lg font-semibold mb-2">Booking Information</h3>
        <p className="mb-4">
          We collect information about your flight bookings, including departure
          and arrival cities, dates, number of passengers, and fare preferences.
        </p>

        <h3 className="text-lg font-semibold mb-2">Communication Data</h3>
        <p className="mb-4">
          If you contact us, we may retain records of our correspondence.
        </p>

        <h2 className="text-xl font-semibold mb-3">
          How We Use Your Information
        </h2>
        <ul className="list-disc list-inside mb-4">
          <li>
            <strong>To Process Bookings:</strong> We use your information to
            process your flight bookings, including issuing tickets and
            providing travel confirmations.
          </li>
          <li>
            <strong>Customer Support:</strong> We use your information to
            respond to your inquiries and provide customer support.
          </li>
          <li>
            <strong>Improve Our Services:</strong> We may use your information
            to analyze usage patterns, improve our website and services, and
            personalize your experience.
          </li>
          <li>
            <strong>Marketing and Promotions:</strong> With your consent, we may
            use your email address to send you promotional offers and
            newsletters. You can opt-out of these communications at any time.
          </li>
          <li>
            <strong>Legal Compliance:</strong> We may use your information to
            comply with legal obligations or to protect our rights.
          </li>
        </ul>

        <h2 className="text-xl font-semibold mb-3">Information Sharing</h2>
        <p className="mb-4">
          We do not sell or rent your personal information to third parties for
          marketing purposes. We may share your information with trusted
          third-party service providers who assist us in operating our website
          and providing services to you, but only as necessary to perform their
          functions. These service providers are contractually obligated to
          protect your personal information.
        </p>
        <p className="mb-4">
          We may also disclose your information if required by law or to protect
          our rights or the rights of others.
        </p>

        <h2 className="text-xl font-semibold mb-3">Data Security</h2>
        <p className="mb-4">
          We implement appropriate security measures to protect your personal
          information from unauthorized access, use, disclosure, alteration, or
          destruction. However, no method of transmission over the internet or
          electronic storage is completely secure.
        </p>

        <h2 className="text-xl font-semibold mb-3">Your Rights</h2>
        <p className="mb-4">
          You have the right to access, correct, or delete your personal
          information. You also have the right to opt out of marketing
          communications. To exercise your rights, please contact us at{" "}
          <a href="mailto:privacy@Travelwaxx.com" className="text-blue-500">
            privacy@Travelwaxx.com
          </a>
          .
        </p>

        <h2 className="text-xl font-semibold mb-3">Children's Privacy</h2>
        <p className="mb-4">
          Our website is not intended for children under the age of 13. We do
          not knowingly collect personal information from children.
        </p>

        <h2 className="text-xl font-semibold mb-3">
          Changes to This Privacy Policy
        </h2>
        <p className="mb-4">
          We may update this Privacy Policy from time to time. We will notify
          you of any material changes by posting the updated policy on our
          website.
        </p>

        <h2 className="text-xl font-semibold mb-3">Contact Us</h2>
        <p className="mb-4">
          If you have any questions or concerns about this Privacy Policy,
          please contact us at{" "}
          <a href="mailto:privacy@Travelwaxx.com" className="text-blue-500">
            privacy@Travelwaxx.com
          </a>
          .
        </p>

        <p className="mb-4">
          <strong>Travelwaxx</strong>
          +1 877-513-5011
        </p>
      </div>
    </div>
  );
}

export default Policy;
