import React, { useState } from "react";
import { TbWorld } from "react-icons/tb";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { IoMdCall } from "react-icons/io";
import { destinations } from "../../Container/Data/destination";
import { RxHamburgerMenu } from "react-icons/rx";
import { MdClose } from "react-icons/md";
import LogoBlack from "../../assets/images/logo-black.png";
import Custom from "../../assets/images/custom.png";
import LogoWhite from "../../assets/images/logo-white.png";

function Header({ color }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorE2, setAnchorE2] = React.useState(null);
  const [showHeader, setShowHeader] = React.useState(false);
  const open = Boolean(anchorEl);
  const destination = Boolean(anchorE2);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleDestinationClick = (event) => {
    setAnchorE2(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setAnchorE2(null);
  };

  return (
    <>
      <div className="flex items-center justify-between sticky bg-transparent max-md:px-3">
        <div className="flex items-center">
          <div
            className="mr-4 hidden max-[1084px]:block"
            onClick={() => {
              setShowHeader(!showHeader);
            }}
          >
            <RxHamburgerMenu
              color={color == "black" ? "rgba(255,255,255,0.7)" : "black"}
              size={23}
            />
          </div>

          <img src={LogoBlack} alt="" className="w-44" />
        </div>

        <div className="flex items-center max-[1084px]:hidden">
          <Link to="/">
            <Button
              id="basic-button"
              style={{
                color:
                  color == "black"
                    ? "rgba(255,255,255,0.7)"
                    : "rgba(0,0,0,0.7)",
                textTransform: "capitalize",
                fontFamily: `Rethink Sans", sans-serif !important`,
              }}
            >
              Home
            </Button>
          </Link>
          <div className="">
            <Button
              id="basic-button"
              style={{
                color:
                  color == "black"
                    ? "rgba(255,255,255,0.7)"
                    : "rgba(0,0,0,0.7)",
                textTransform: "capitalize",
                fontFamily: `Rethink Sans", sans-serif !important`,
              }}
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              About Company
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <Link to="/in/about">
                <MenuItem
                  onClick={handleClose}
                  className="px-4 py-2 hover:bg-gray-200"
                >
                  About Us
                </MenuItem>
              </Link>
              <Link to="/in/terms">
                <MenuItem onClick={handleClose}>Terms & Conditions</MenuItem>
              </Link>
              <Link to="/in/policy">
                <MenuItem onClick={handleClose}>Privacy Policy</MenuItem>
              </Link>
            </Menu>
          </div>
          <div className="text-base text-white/70 mx-4 font-light">
            <Button
              id="destination-menu"
              style={{
                color:
                  color == "black"
                    ? "rgba(255,255,255,0.7)"
                    : "rgba(0,0,0,0.7)",
                textTransform: "capitalize",
                fontFamily: `Rethink Sans", sans-serif !important`,
              }}
              aria-controls={open ? "destination-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleDestinationClick}
            >
              Top Destinations
            </Button>
            <Menu
              id="destination-menu"
              anchorEl={anchorE2}
              open={destination}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "destination-menu",
              }}
            >
              {destinations.map((destination, index) => (
                <Link
                  to={`/in/destination/${destination.title
                    .split(" ")
                    .join("")
                    .toLowerCase()}`}
                >
                  <MenuItem
                    key={index}
                    onClick={handleClose}
                    className="px-4 py-2 hover:bg-gray-200"
                  >
                    {destination.title}
                  </MenuItem>
                </Link>
              ))}
            </Menu>
          </div>
          <Button
            id="basic-button"
            style={{
              color:
                color == "black" ? "rgba(255,255,255,0.7)" : "rgba(0,0,0,0.7)",
              textTransform: "capitalize",
              fontFamily: `Rethink Sans", sans-serif !important`,
            }}
          >
            Blogs
          </Button>
          <Link to="/in/contact">
            <Button
              id="basic-button"
              style={{
                color:
                  color == "black"
                    ? "rgba(255,255,255,0.7)"
                    : "rgba(0,0,0,0.7)",
                textTransform: "capitalize",
                fontFamily: `Rethink Sans", sans-serif !important`,
              }}
            >
              Contact
            </Button>
          </Link>
        </div>
        <div className="flex items-center">
          <a
            href="tel:+18338352670"
            className="bg-[#fc6c3a] py-3 px-5 rounded-md text-white text-lg flex items-center justify-center"
          >
            <span className="max-md:block">
              <img src={Custom} alt="" className="w-9" />
            </span>{" "}
            <span className="max-md:hidden font-bold ml-3">
              Toll Free +1 833-835-2670
            </span>
          </a>
        </div>
      </div>
      {showHeader && (
        <div
          className={`h-screen w-full  ${
            color === "black" ? "bg-black" : "bg-white"
          } fixed top-0 right-0 left-0 bottom-0 z-50 py-4 px-6 flex flex-col`}
        >
          <div className="flex items-center mb-6">
            <div
              className="mr-4"
              onClick={() => {
                setShowHeader(!showHeader);
              }}
            >
              <MdClose
                color={color == "black" ? "rgba(255,255,255,0.7)" : "black"}
                size={23}
              />
            </div>
            <span
              className={`text-2xl ${
                color === "black" ? "text-white" : "text-black"
              } font-semibold`}
            >
              evale
            </span>
          </div>
          <Link to="/" className="my-5 w-full">
            <Button
              id="basic-button"
              style={{
                color:
                  color == "black"
                    ? "rgba(255,255,255,0.7)"
                    : "rgba(0,0,0,0.7)",
                width: "100%",
                textTransform: "capitalize",
                fontFamily: `Rethink Sans", sans-serif !important`,
              }}
            >
              Home
            </Button>
          </Link>
          <div className="my-5">
            <Button
              id="basic-button"
              style={{
                color:
                  color == "black"
                    ? "rgba(255,255,255,0.7)"
                    : "rgba(0,0,0,0.7)",
                textTransform: "capitalize",
                fontFamily: `Rethink Sans", sans-serif !important`,
                width: "100%",
              }}
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              About Company
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <Link to="/in/about">
                <MenuItem
                  onClick={handleClose}
                  className="px-4 py-2 hover:bg-gray-200"
                >
                  About Us
                </MenuItem>
              </Link>
              <Link to="/in/terms">
                <MenuItem onClick={handleClose}>Terms & Conditions</MenuItem>
              </Link>
              <MenuItem onClick={handleClose}>Privacy Policy</MenuItem>
            </Menu>
          </div>
          <div className="text-base text-white/70 font-light my-5">
            <Button
              id="destination-menu"
              style={{
                color:
                  color == "black"
                    ? "rgba(255,255,255,0.7)"
                    : "rgba(0,0,0,0.7)",
                textTransform: "capitalize",
                width: "100%",
                fontFamily: `Rethink Sans", sans-serif !important`,
              }}
              aria-controls={open ? "destination-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleDestinationClick}
            >
              Top Destinations
            </Button>
            <Menu
              id="destination-menu"
              anchorEl={anchorE2}
              open={destination}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "destination-menu",
              }}
            >
              {destinations.map((destination, index) => (
                <Link
                  to={`/in/destination/${destination.title
                    .split(" ")
                    .join("")
                    .toLowerCase()}`}
                >
                  <MenuItem
                    key={index}
                    onClick={handleClose}
                    className="px-4 py-2 hover:bg-gray-200"
                  >
                    {destination.title}
                  </MenuItem>
                </Link>
              ))}
            </Menu>
          </div>
          <Button
            id="basic-button"
            style={{
              color:
                color == "black" ? "rgba(255,255,255,0.7)" : "rgba(0,0,0,0.7)",
              textTransform: "capitalize",
              fontFamily: `Rethink Sans", sans-serif !important`,
              width: "100%",
            }}
          >
            Blogs
          </Button>
          <Link to="/in/contact" className="my-5">
            <Button
              id="basic-button"
              style={{
                color:
                  color == "black"
                    ? "rgba(255,255,255,0.7)"
                    : "rgba(0,0,0,0.7)",
                textTransform: "capitalize",
                width: "100%",
                fontFamily: `Rethink Sans", sans-serif !important`,
              }}
            >
              Contact
            </Button>
          </Link>
        </div>
      )}
    </>
  );
}

export default Header;
